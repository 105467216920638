<template>
  <div>
    <button
      v-if="!modelValue"
      aria-label="Upload image"
      class="!text-on-surface/10 hover:bg-primary/5 flex aspect-video h-full w-full flex-col items-center justify-center gap-4 overflow-hidden rounded !border border-dashed !border-current transition-all"
      :class="{ 'text-error': !!errorMessage }"
      type="button"
      @click="onClick"
    >
      <app-icon icon="ph:image" :size="50" />
    </button>

    <div v-else class="group relative h-full w-full">
      <app-image
        alt=""
        class="h-full w-full overflow-hidden rounded"
        :src="modelValue"
      />

      <v-btn-group
        class="absolute right-2 top-2 !h-[30px] opacity-0 group-hover:opacity-100"
        density="comfortable"
      >
        <v-btn
          color="error"
          icon="ph:trash"
          size="small"
          @click="value = null"
        />
        <v-btn icon="ph:pen" size="small" @click="onClick" />
      </v-btn-group>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useField } from "vee-validate";

import { useMediaUploadWidget } from "~/shared/media/composables/media-upload-widget.hook";

import { uniqueFieldName } from "../../form/composables/form-input.hook";

const properties = withDefaults(
  defineProps<{
    preset: string;
    croppingAspectRatio?: number;
    name?: string;
    modelValue?: string | null;
    required?: boolean;
  }>(),
  {
    croppingAspectRatio: undefined,
    name: uniqueFieldName(),
    modelValue: undefined,
  },
);

const { value, errorMessage } = useField(
  properties.name,
  {
    required: properties.required,
  },
  {
    initialValue: properties.modelValue,
    syncVModel: true,
  },
);

const { open } = useMediaUploadWidget({
  preset: properties.preset,
  croppingAspectRatio: properties.croppingAspectRatio,
});

const onClick = async () => {
  const { url } = await open();

  value.value = url;
};
</script>
