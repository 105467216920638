declare global {
  interface Window {
    cloudinary: {
      openUploadWidget(
        options: Record<string, unknown>,
        handler: (
          error: Error,
          result: { event: string; info: { secure_url: string } },
        ) => void,
      ): void;
    };
  }
}

interface UploadWidgetHookOptions {
  preset: string;
  croppingAspectRatio?: number;
  type?: "video";
}

const ensureCloudinaryWidgetIsLoaded = () => {
  if (window.cloudinary) return Promise.resolve();

  const script = document.createElement("script");

  return new Promise<void>((resolve) => {
    script.addEventListener("load", () => {
      resolve();
    });
    script.src = "https://upload-widget.cloudinary.com/global/all.js";
    document.body.append(script);
  });
};

export const useMediaUploadWidget = ({
  preset,
  croppingAspectRatio,
  type,
}: UploadWidgetHookOptions) => {
  const { isE2E } = useEnvironment();
  const {
    public: {
      cloudinary: { cloudName },
    },
  } = useRuntimeConfig();
  const loading = ref(false);

  const open = async (): Promise<{ url: string }> => {
    if (isE2E) {
      return {
        url: "https://imgsrc.cineserie.com/2020/03/o-die-hard-john-mcclane-facebook.jpg",
      };
    }

    loading.value = true;
    await ensureCloudinaryWidgetIsLoaded();
    loading.value = false;

    return new Promise((resolve) => {
      window.cloudinary.openUploadWidget(
        {
          sources: ["local", "url"],
          cloudName,
          uploadPreset: preset,
          cropping: true,
          multiple: false,
          showSkipCropButton: true,
          croppingAspectRatio,
          clientAllowedFormats: type ?? "image",
        },
        (error, result) => {
          if (result.event === "success")
            return resolve({ url: result.info.secure_url });
        },
      );
    });
  };

  return {
    loading: readonly(loading),
    open,
  };
};
